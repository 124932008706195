<template>
    <div id="dash_email">
    </div>
  </template>
    
  <script>
  
  export default {
    name: 'DashEmail',
    components: {
  
    }
  }
  </script>
    
  <style scoped>
  #dash_email {
    color: #d6d6d6;
    width: 100%;
    background-color: #1a1a1a;
    padding: 20px;
  }
    /* background-color: #1a1a1a; */
  </style>
    