import DashBoard from './pages/AdminDash.vue';
import DashBoard_EMAIL from './pages/dashboard/DashEmail.vue';
import DashBoard_DONATIONS from './pages/dashboard/DashDonate.vue';
import DashBoard_VISITORS from './pages/dashboard/DashVisitor.vue';


import EditPages from './pages/AdminEdit.vue';
import EditPages_BANNER from './pages/edit/EditBanner.vue';
import EditPages_NETS from './pages/edit/EditNet.vue';
import EditPages_REPEATERS from './pages/edit/EditRepeater.vue';
import EditPages_MEETING from './pages/edit/EditMeeting.vue';
import EditPages_DMR from './pages/edit/EditDmr.vue';
import EditPages_ROSTER from './pages/edit/EditRoster.vue';
import EditPages_OFFICERS from './pages/edit/EditOfficer.vue';
import EditPages_CONSTITUTION from './pages/edit/ByLaws.vue';


import Settings from './pages/AdminSetting.vue';
import Settings_PASSWORD from './pages/setting/SettingPassword.vue';
import Settings_COLORS from './pages/setting/SettingColor.vue';

import DevLogs from './pages/DevLogs.vue';

import Invalid from './pages/InvalidPage.vue';


export default[
    { path: '/', redirect: '/dashboard'},
    { path: '/dashboard', component: DashBoard},
    { path: '/dashboard/email', component: DashBoard_EMAIL},
    { path: '/dashboard/donations', component: DashBoard_DONATIONS},
    { path: '/dashboard/visitors', component: DashBoard_VISITORS},

    { path: '/edit', component: EditPages},
    { path: '/edit/banner', component: EditPages_BANNER},
    { path: '/edit/repeater', component: EditPages_REPEATERS},
    { path: '/edit/net', component: EditPages_NETS},
    { path: '/edit/officer', component: EditPages_OFFICERS},
    { path: '/edit/dmr', component: EditPages_DMR},
    { path: '/edit/roster', component: EditPages_ROSTER},
    { path: '/edit/meeting', component: EditPages_MEETING},
    { path: '/edit/constitution', component: EditPages_CONSTITUTION},
    

    { path: '/settings', component: Settings},
    { path: '/settings/password', component: Settings_PASSWORD},
    { path: '/settings/color', component: Settings_COLORS},

    { path: '/devlogs', component: DevLogs},

    { path: '*', redirect: '/404'},
    { path: '/404', component: Invalid},
];