<template>
  <div id="edit">
    <PackHead 
    @userSelectedSort="updateComputedSort" 
    @userAddNew="userAddNew"
    :titles="titles"
    :values="newValues"
    :maxsort="rowsDataLength"
    :inputtypes="inputTypes"
    :showrowoption="true"/>
    
    <div class="rowheader">
      <div v-for="(i, index) in 5" :key="index"> {{ titles[index] }}</div>
    </div>

    <div class="row_container">
      <PackRow 
      :key="index" 
      :rowsData="rowsData[index].dataArray"
      :idNum="rowsData[index].id" 
      @userEdit="userEdit"
      v-for="(i, index) in rowNum"/>
    </div>

    <PackFooter 
    :key="rowNum" 
    :arraysize="rowsDataLength" 
    :rowcount="rowNum"
    @reRenderRows="updateRows" 
    v-if="allowFooter" />
  </div>
</template>
    
<script>
import Vue from 'vue';
import PackHead from '../../components/packs/PackHead.vue';
import PackFooter from '../../components/packs/PackFooter.vue';
import PackRow from '../../components/packs/PackRow.vue';

export default {
  name: 'EditNet',
  components: {
    PackHead,
    PackFooter,
    PackRow
  },
  data() {
    return {
      netURL: "https://us-east-1.aws.data.mongodb-api.com/app/app-0-yyrfg/endpoint/net",
      rowsData: [],
      rowsDataLength: 0,
      rowNum: 10,
      allowFooter: false,
      activeRow: 1,
      titles: ["Day", "Frequency"," Net_Sponsor", "PL", "Rep_Location", "Time"],
      inputTypes: ["text", "text", "text", "text", "text", "text"],
      newValues: ["...", "...", "...", "...", "...", "..."],
    }
  },
  methods:{
    userEdit(obj){
      console.log('1', obj)
      let generateData = {
        titles: this.titles,
        values: [obj[0], obj[1], obj[2], obj[3], obj[4], obj[5]],
        type: this.inputTypes,
        id: obj._id
      }
      this.$emit('userEdit',generateData)
    },
    updateComputedSort(a)
    {
      this.rowNum = Number(a);
      this.rowsDataLength = this.rowsData.length
    },
    updateRows(activeR){
      this.activeRow = Number(activeR);
    },
    userAddNew(genData){
      this.$emit('userAddNew',genData)
    },
  },
  mounted(){
    Vue.axios.get(this.netURL).then((response) => {
      this.rowNum = response.data.length

      for(let i = 0; i < response.data.length; i++)
      {
        let tempObj = {
          dataArray: [
          response.data[i].day,
          response.data[i].freq,
          response.data[i].time,
          response.data[i].rep_loc,
          response.data[i].pl,
          response.data[i].time
          ],
          id: response.data[i]._id
        }
        this.rowsData.push(tempObj)
      }
      this.allowFooter = true;
      this.rowsDataLength = response.data.length;
    }) 
    }
  }
</script>
    
<style scoped>
#edit {
  color: #d6d6d6;
  width: 100%;
  background-color: #1a1a1a;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* background-color: #1a1a1a; */
</style>
    