<template>
    <div id="dev">
        <div class="logEntry" v-for="(entry, index) in logsReceived" :key="index">
            <div class="logEntry_date">
                {{ convertDate(entry.date) }}
            </div>
            <div class="logEntry_message">
                {{ entry.message }}
            </div>
            <div id="notice">
                logs autogenerated with chatGPT
            </div>
        </div>
    </div>
</template>
    
<script>
import Vue from 'vue';

export default {
    name: 'DevLogs',
    components: {

    },
    data(){
        return{
            getURL: "https://us-east-1.aws.data.mongodb-api.com/app/app-0-yyrfg/endpoint/devlogs",
            logsReceived: []
        }
    },
    mounted(){
        Vue.axios.get(this.getURL).then((response) => {
            this.logsReceived = response.data;
        })
    },
    methods:{
        convertDate(date){
            let options = 
            { 
                weekday: 'long', 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit',
                hour12: true
            };
            let dt = new Date(date);
            return dt.toLocaleDateString("en-US", options);
        }
    }
}
</script>
    
<style scoped>
#dev {
    width: 100%;
    background-color: #1a1a1a;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.logEntry {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.logEntry_date{
    font-weight: bold;
    color: #d6d6d6;
}
.logEntry_message{
    padding-left: 20px;
    color: #d6d6d6b6;
}
/* background-color: #1a1a1a; */</style>
    